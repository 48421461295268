import { notApplicableString } from '@/lib/constants/constants/analytics';
import { PetStatus } from '@/lib/constants/types/analytics';
import {
  AnalyticsEventStorage,
  AnalyticsInitiatingComponent,
} from '@/lib/storageClasses/analyticsEventStorage';
import { PetFlowType } from '@/lib/utils/analytics/petSearch';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

/**
 * The data structure for the event data, we can add more properties as needed
 *
 * @interface EventData
 */
export interface EventData {
  /** The method behind the operation */
  method?: string;
  /** The status of the pet (Lost, Found, Safe at Home) */
  petStatus?: PetStatus;
  /** The text on the button that was clicked to get to this event */
  clickText?: string;
  /** The id of the pet entity */
  petId?: number;
  /** The name of the pet */
  petName?: string;
  /** The zip code associated with the pet */
  locationId?: string;
  /** The species of the pet */
  species?: string;
  /** The start date of the search */
  start?: string;
  /** The flow type */
  flowType?: PetFlowType;
}

/** This is the data structure that can be returned from the provider */
export interface IEventContext {
  /** This is the method for updating the data */
  updateData: (newData: EventData) => void;
  /** This is the method for clearing the data */
  clearData: () => void;
  /** Get data */
  getData: () => EventData | undefined;
  /** Set the initiating component state */
  setInitiatingComponent: (component: AnalyticsInitiatingComponent) => void;
  /** The initiating component */
  getInitiatingComponent: () => AnalyticsInitiatingComponent;
}

/**
 * The default event context
 *
 * @constant {IEventContext} defaultEventContext
 */
export const defaultEventContext: IEventContext = {
  /** This method is used to update the data */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateData: () => {},
  /**
   * This method is used to get the data
   *
   * @returns {undefined} - The data
   */
  getData: () => undefined,
  /** This method is used to clear the data */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearData: () => {},
  /** Set the initiating component state */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInitiatingComponent: () => {},
  /**
   * The default initiating component
   *
   * @returns {string} - The initiating component
   */
  getInitiatingComponent: (): AnalyticsInitiatingComponent =>
    notApplicableString,
};

/** This is the context */
const EventContext = createContext<IEventContext>(defaultEventContext);

/**
 * The interface for the EventContextProvider props
 *
 * @interface
 */
interface IEventContextProvider {
  /** The children wrapped by the provider */
  children: ReactNode;
}

/**
 * The EventContextProvider component.
 *
 * @param {IEventContextProvider} props - The props for the
 *   EventContextProvider.
 * @returns {Element} - The EventContextProvider.
 */
export function EventContextProvider({
  children,
}: IEventContextProvider): JSX.Element {
  // Make instance of AnalyticsEventStorage referentially stable so context methods can be passed to the dependency array
  const analyticsEventStorage = useMemo(() => new AnalyticsEventStorage(), []);

  /**
   * Update the state of data
   *
   * @param {EventData} newData - The new data
   * @returns {void}
   */
  const updateData = useCallback(
    (newData: EventData) => {
      const storedData = analyticsEventStorage.get();
      const currentData = storedData || {};
      if (currentData && newData) {
        newData = { ...currentData, ...newData };
      }
      analyticsEventStorage.set(newData);
    },
    [analyticsEventStorage]
  );

  /**
   * Clear the state of data
   *
   * @returns {void}
   */
  const clearData = useCallback(() => {
    analyticsEventStorage.delete();
  }, [analyticsEventStorage]);

  /**
   * Return the state of data, if it is not there then check the storage
   *
   * @returns {EventData | undefined} - The data
   */
  const getData = useCallback(() => {
    return analyticsEventStorage.get() || undefined;
  }, [analyticsEventStorage]);

  /**
   * Set the initiating component
   *
   * @param {AnalyticsInitiatingComponent} component - The initiating component
   */
  const setInitiatingComponent = useCallback(
    (component: AnalyticsInitiatingComponent) => {
      analyticsEventStorage.setInitiatingComponent(component);
    },
    [analyticsEventStorage]
  );

  /**
   * Retrieve the initiating component
   *
   * @returns {AnalyticsInitiatingComponent} - The initiating component
   */
  const getInitiatingComponent = useCallback(() => {
    return analyticsEventStorage.getInitiatingComponent();
  }, [analyticsEventStorage]);

  return (
    <EventContext.Provider
      value={{
        clearData,
        getData,
        getInitiatingComponent,
        setInitiatingComponent,
        updateData,
      }}
    >
      {children}
    </EventContext.Provider>
  );
}

/**
 * This is the hook for the event context
 *
 * @returns {IEventContext} - The context
 */
export const useEventContext = (): IEventContext => useContext(EventContext);
