import Divider from '@/components/atoms/Divider/Divider';
import { gridMaxWidth } from '@/components/atoms/Grid/GridClasses';
import Icon from '@/components/atoms/Icon/Icon';
import LostLogo from '@/components/atoms/LostLogo/LostLogo';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import SocialLinks from '@/components/molecules/SocialLinks/SocialLinks';
import { useEventContext } from '@/lib/hooks/analytics/useEventContext';
import { useUserContext } from '@/lib/hooks/userContext/UserContext';
import { AnalyticsInitiatingComponent } from '@/lib/storageClasses/analyticsEventStorage';
import { Disclosure } from '@headlessui/react';
import Link from 'next/link';
import { Fragment } from 'react';
import { IMenuItem, MenuLink } from './FooterLinks';

/**
 * Determine Initiating Component for analytics purposes
 *
 * @param {string} linkName - The name of the link
 * @returns {AnalyticsInitiatingComponent} - The initiating component
 */
export const determineInitiatingComponent = (
  linkName: string
): AnalyticsInitiatingComponent => {
  switch (linkName) {
    case 'I Lost a Pet':
      return 'I Lost A Pet Footer Link / All Pages';
    case 'I Found a Pet':
      return 'I Found A Pet Footer Link / All Pages';
    default:
      return 'NA';
  }
};

/**
 * Desktop Footer Menu Item This is the menu item that is displayed on desktop.
 *
 * @param {IMenuItem} props - The props for the DesktopFooterMenuItem component
 * @returns {React.FC<IMenuItem>} Desktop Footer Menu Item Component
 */
const DesktopFooterMenuItem: React.FC<IMenuItem> = ({ name, links }) => {
  const { setInitiatingComponent } = useEventContext();

  return (
    <>
      <span
        className={`font-petco font-bold uppercase text-base-300 text-overline`}
      >
        {name}
      </span>
      <ul className="mt-[17px]">
        {links.map((link: MenuLink) => (
          <li key={link.name} className={`mb-4`}>
            <Link href={link.url}>
              <a
                className="text-body5 hover:text-base-300 font-petco text-neutral-800 focus:ring-focus-400 focus-visible:ring-focus-400 focus-visible:outline-focus-400"
                target={link.target}
                onClick={() => {
                  link?.onClick;
                  if (
                    link.name === 'I Lost a Pet' ||
                    link.name === 'I Found a Pet'
                  ) {
                    setInitiatingComponent(
                      determineInitiatingComponent(link.name)
                    );
                  }
                }}
              >
                {link.name}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

/**
 * Mobile Footer Menu Item This is the menu item that is displayed on mobile.
 *
 * @param {IMenuItem} props - The props for the MobileFooterMenuItem component
 * @returns {React.FC<IMenuItem>} Mobile Footer Menu Item Component
 */
const MobileFooterMenuItem: React.FC<IMenuItem> = ({ name, links }) => {
  const { setInitiatingComponent } = useEventContext();

  return (
    <Disclosure
      as="div"
      className="min-h-[56px] border-b-2 border-b-neutral-300"
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            data-testid="mobile-footer-menu-button"
            className={`w-full h-full inline-flex justify-between min-h-[56px] text-left items-center font-petco font-bold uppercase text-base-300 text-overline`}
          >
            {name}
            <Icon
              icon={open ? 'arrowUp' : 'arrowDown'}
              size={24}
              colorType="base"
              colorShade={400}
              id={`footer-arrow-${name}`}
            />
          </Disclosure.Button>
          <Disclosure.Panel as="ul" className="-mt-2.5 mb-2.5">
            {links.map((link: MenuLink) => (
              <li key={link.name} data-testid="mobile-footer-menu-item">
                <Link href={link.url}>
                  <a
                    className={`font-petco text-body5 text-neutral-800`}
                    target={link.target}
                    onClick={() => {
                      if (
                        link.name === 'I Lost a Pet' ||
                        link.name === 'I Found a Pet'
                      ) {
                        setInitiatingComponent(
                          determineInitiatingComponent(link.name)
                        );
                      }
                      link?.onClick;
                    }}
                  >
                    {link.name}
                  </a>
                </Link>
              </li>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

/**
 * IFooterBottomLinks This is the interface for the bottom links underneath the
 * Disclosure text.
 *
 * @interface
 */
interface IFooterBottomLinks {
  /** The links to display. */
  links: MenuLink[];
}

/**
 * FooterBottomLinks This is the bottom links underneath the Disclosure text.
 *
 * @param {IFooterBottomLinks} props - The props for the FooterBottomLinks
 * @returns {React.FC<IFooterBottomLinks>} Footer Bottom Links Component
 */
const FooterBottomLinks: React.FC<IFooterBottomLinks> = ({ links }) => {
  return (
    <Paragraph size="overline">
      <>
        {links.map((link: MenuLink, index: number) => (
          <Fragment key={link.name}>
            <Link href={link.url}>
              <a
                className="focus-visible:outline-focus-400 hover:text-base-300"
                target={link.target}
              >
                {link.name}
              </a>
            </Link>
            {index !== links.length - 1 && ' | '}
          </Fragment>
        ))}
      </>
    </Paragraph>
  );
};

/**
 * IFooter This is the interface for the Footer component.
 *
 * @interface
 */
export interface IFooter {
  /** The Copyright Text at the bottom of the footer. */
  disclosure: string;
  /** The menu items to display in the footer in the first column. */
  columnOne: IMenuItem;
  /** The menu items to display in the footer in the second column. */
  columnTwo: IMenuItem;
  /** The menu items to display in the footer in the third column. */
  columnThree: IMenuItem;
  /** The menu items to display in the footer in the fourth column. */
  columnFour: IMenuItem;
  /**
   * The menu items to display in the footer in the fourth column when user is
   * logged in.
   */
  columnFourLoggedIn: IMenuItem;
  /** The links to display underneath the disclosure text. */
  bottomLinks: MenuLink[];
}

/**
 * Footer Used to display the footer of the application.
 *
 * @param {IFooter} props - The props for the Footer component
 * @returns {React.FC<IFooter>} Footer Component
 */
const Footer: React.FC<IFooter> = ({
  disclosure,
  columnOne,
  columnTwo,
  columnThree,
  columnFour,
  columnFourLoggedIn,
  bottomLinks,
}: IFooter) => {
  const { userType } = useUserContext();

  return (
    <footer
      data-testid="footer"
      className={`${gridMaxWidth} flex flex-col text-center mt-5`}
    >
      {/* Mobile View */}
      <div
        className="flex flex-col md:hidden border-t-[5px] border-t-base-400 border-t-solid text-left"
        data-testid="footer-mobile"
      >
        <MobileFooterMenuItem {...columnOne} />
        <MobileFooterMenuItem {...columnTwo} />
        <MobileFooterMenuItem {...columnThree} />
        {userType === null ? (
          <MobileFooterMenuItem {...columnFour} />
        ) : (
          <MobileFooterMenuItem {...columnFourLoggedIn} />
        )}
      </div>
      {/* Desktop View */}
      <div
        className="hidden md:grid grid-flow-row-dense grid-cols-6 border-t-[5px] py-10 border-t-base-400 border-t-solid"
        data-testid="footer-desktop"
      >
        <div className="col-span-2">
          <div className="w-[200px]">
            <LostLogo variant="standard" />
          </div>
        </div>
        <div className="col-span-1 text-left">
          <DesktopFooterMenuItem {...columnOne} />
        </div>
        <div className="col-span-1 text-left">
          <DesktopFooterMenuItem {...columnTwo} />
        </div>
        <div className="col-span-1 text-left">
          <DesktopFooterMenuItem {...columnThree} />
        </div>
        <div className="col-span-1 text-left">
          {userType === null ? (
            <DesktopFooterMenuItem {...columnFour} />
          ) : (
            <DesktopFooterMenuItem {...columnFourLoggedIn} />
          )}
        </div>
      </div>
      <div className="hidden md:flex">
        <Divider type="lightGrey" />
      </div>
      {/* Shared for Mobile and Desktop */}
      <div className="flex justify-center my-8">
        <SocialLinks size={24} />
      </div>
      <div className="max-w-[780px] mx-auto mb-4">
        <Paragraph size="overline">{disclosure}</Paragraph>
      </div>
      <div className="mb-8">
        <FooterBottomLinks links={bottomLinks} />
      </div>
    </footer>
  );
};

export default Footer;
