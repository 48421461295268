import { chooseIcon, IconType } from '@/components/atoms/Icon/Icon';

/**
 * ISocialLinks Interface for a SocialLink Item
 *
 * @interface
 */
export interface ISocialLinkItem {
  /** The name of the social link */
  name: string;
  /** The url of the social link */
  url: string;
  /** The icon of the social link */
  icon: IconType;
}

/** Social Links */
export const defaultSocialLinks: ISocialLinkItem[] = [
  {
    name: 'Facebook',
    icon: 'facebook',
    url: 'https://www.facebook.com/petcolovelost',
  },
  {
    name: 'Twitter',
    icon: 'twitter',
    url: 'https://twitter.com/petcolovelost',
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    url: 'https://www.instagram.com/petcolovelost',
  },
  {
    name: 'Youtube',
    icon: 'youtube',
    url: 'https://www.youtube.com/petcolove',
  },
  {
    name: 'Nextdoor',
    icon: 'nextdoor',
    url: 'https://nextdoor.com/pages/petco-love-lost',
  },
];

/**
 * ISocialLinks Interface for SocialLinks component
 *
 * @interface
 */
export interface ISocialLinks {
  /** The size of the Icons to display. */
  size: number;
  /**
   * The optional social links to display
   *
   * @default defaultSocialLinks
   */
  socialLinks?: ISocialLinkItem[];
  /**
   * The optional classes
   *
   * @default defaultSocialLinks
   */
  classes?: string;
}

/**
 * Social Links A list of our social links to display out in the application.
 *
 * @param {ISocialLinks} props - The props for the SocialLinks component
 * @returns {React.FC<ISocialLinks>} Social Links Component
 */
const SocialLinks: React.FC<ISocialLinks> = ({
  size,
  classes,
  socialLinks = defaultSocialLinks,
}) => {
  return (
    <div data-testid="social-links" className={`flex space-x-6 ${classes}`}>
      {socialLinks.map((socialLink) => {
        const Icon = chooseIcon(socialLink.icon);

        return (
          <div key={socialLink.name}>
            <a
              href={socialLink.url}
              target="_blank"
              rel="noreferrer"
              className="focus-visible:outline-focus-400 text-base-400 hover:text-base-300"
            >
              <Icon size={size} color="currentColor" />
              <span className="sr-only">{socialLink.name}</span>
            </a>
          </div>
        );
      })}
    </div>
  );
};

SocialLinks.defaultProps = {
  classes: '',
};

export default SocialLinks;
